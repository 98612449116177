import React, {useContext} from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import { LanguageContext } from "../context"

const PageSimple = ({data, location, pageContext }) => {
  const post = data?.nodePageTramuntana
  const { t } = useContext(LanguageContext)
  const translationPaths = {
   en: `/en${post?.path?.alias}`,
   es: `/es${post?.path?.alias}`,
   ca: `${post?.path?.alias}`
 }

  return (
    <div>
      <Layout
        translationPaths={translationPaths}
        location={location}
        langcode={post?.langcode}
        className="simple-pages"
        >
        <Header
          lang={pageContext?.langcode}
          location={location}
        />
        <Seo
            title={post?.title}
            lang={post?.langcode}
            description={post?.body?.summary}
            image={post?.relationships?.field_image?.image_style_uri?._653_432}
          />
        <section className="m-t-90 m-b-80 small-m-t-40 small-m-b-40 p-t-72 m-b-80 small-m-t-40 small-m-b-40 medium-m-b-24">
          <div className="container">
            <Title
              title={post?.title}
              color="brown"
              type={1}
              className="size-56-line-64 d-flex justify-content-center small-p-t-24 small-size-22 medium-size-32  small-p-b-32 medium-p-t-40 medium-p-b-40 m-b-88-"
            />
              <div
              dangerouslySetInnerHTML={{__html: post?.body?.value}}
              className="simple-page"
            />
          </div>
         </section>
        <Footer
          lang={pageContext?.langcode}
          location={location}
        />
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
query PageSimple ($drupal_internal__nid: Int, $langcode: String!) {
  nodePageTramuntana (
    drupal_internal__nid: {eq: $drupal_internal__nid},
    langcode:{eq: $langcode}
    ){
       drupal_internal__nid
        field_type
        title
        langcode
        path{
          alias
        }
        body{
          processed
          value
        }
      }
}
`

export default PageSimple
